<template>
  <div class="full_popup">
    <div class="row h-100 m-0 align-items-center justify-content-center">
      <div class="col-11 col-md-8 col-lg-6 col-xxl-6 p-0 card_item">
        <div class="row mx-0 h-100 justify-content-center">
          <div class="col-12 p-3 pb-0">
            <div class="row m-0 justify-content-center holder">
              <div class="bg_holder">
                <div class="background" >
                </div>
              </div>
              <div class="col-12 p-3 above">
                <!--MOBILE  CLOSE-->
                <div class="row mb-4 d-md-none">
                  <div class="col-12 text-center">
                    <Button color="dark" size="small" class="white-text" btnText="Back to All Zakaah Calculations" icon2="arrow" @buttonClicked="$emit('close')">
                      <template v-slot:iconStart>
                        <IconArrowBack size="size16" />
                      </template>
                    </Button>
                  </div>
                </div>
                <!--END MOBILE  CLOSE-->
                <!--NAME-->
                <div class="row">
                  <div class="col-md-10 offset-md-1 pt-2">
                    <div class="row justify-content-center d-md-none mb-4">
                      <div class="col-auto text-center p-0 medium green-text-dark font13">
                        ZAKAAH CALCULATION: {{item.zakaatDetailID}}
                      </div>
                    </div>
                    <div class="row justify-content-center">
                      <div class="col-auto text-center p-0 card_title text-uppercase">
                        {{item.zakaatName}}
                        <div class="row m-0 justify-content-center d-none d-md-flex" >
                          <div class="col-12 text-center pt-3 border_bottom">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row justify-content-center d-md-none mt-4">
                      <div class="col-9 text-center p-0 medium green-text-dark font13">
                        <Button color="red" size="small" width="100" btnText="Donate Zakaah" icon="arrow" @buttonClicked="donate">
                          <IconArrowForward size="size16" />
                        </Button>
                        <div class="row m-0 justify-content-center" >
                          <div class="col-9 text-center pt-4 border_bottom">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-1 text-end cursor d-none d-md-block" @click="$emit('close')">
                    <ios-close-icon class="inline-icon green-text-dark" />
                  </div>
                </div>
                <!--END NAME-->
                <div class="row m-0 justify-content-center" >
                  <div class="col-12 px-0 my-3 pt-3">
                    <div class="row mx-0 h-100 justify-content-center">
                      <!--ASSETS-->
                      <div class="col-12 col-md-9 p-0 mb-3">
                        <Button class="d-md-none" color="green_light" :btnText="'Assets | ' + displayAmount(item.netAssets)" alignment="justify-content-between" icon="arrow" @buttonClicked="showAssets = !showAssets" width="100">
                          <IconArrowDown size="size16" v-if="showAssets"/>
                          <IconArrowForward size="size16" v-else />
                        </Button>
                        <Button class="d-none d-md-block" color="green" :btnText="'Assets | ' + displayAmount(item.netAssets)" alignment="justify-content-between" icon="arrow" @buttonClicked="showAssets = !showAssets" width="100">
                          <IconArrowDown size="size16" v-if="showAssets"/>
                          <IconArrowForward size="size16" v-else />
                        </Button>
                        <div class="row mx-0 m-3" v-if="showAssets">
                          <!--BUSINESS ASSETS-->
                          <div class="col-12 mb-2" v-if="item.businessAssets && item.businessAssets.length > 0">
                            <div class="bold green-text border-bottom py-2">Business Assets</div>
                            <div class="row m-0 border-bottom align-items-center" v-for="(asset, key) in item.businessAssets" :key="key">
                              <div class="col p-1">
                                <div class="row">
                                  <div class="col-12 bold font15" v-if="asset.description">
                                    {{asset.description}}
                                  </div>
                                  <div class="col-12 font12" v-if="asset.businessAssetType">
                                    {{ displayDescription(asset.businessAssetType) }}
                                  </div>
                                </div>
                              </div>
                              <div class="col-auto text-end bold font15">
                                {{displayAmount(asset.amount)}}
                              </div>
                            </div>
                          </div>
                          <!--END BUSINESS ASSETS-->
                          <!--CASH ASSETS-->
                          <div class="col-12 mb-2" v-if="item.cashAssets && item.cashAssets.length > 0">
                            <div class="bold green-text border-bottom py-2">Cash Assets</div>
                            <div class="row m-0 border-bottom align-items-center" v-for="(asset, key) in item.cashAssets" :key="key">
                              <div class="col p-1">
                                <div class="row">
                                  <div class="col-12 bold font15" v-if="asset.description">
                                    {{asset.description}}
                                  </div>
                                  <div class="col-12 font12" v-if="asset.cashAssetType">
                                    {{ displayDescription(asset.cashAssetType) }}
                                  </div>
                                  <div class="col-12 font12">
                                    {{asset.currency.symbol}} {{parseFloat(Math.round((asset.amount) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
                                  </div>
                                </div>
                              </div>
                              <div class="col-auto text-end bold font15">
                                {{displayAmount(asset.amount)}}
                              </div>
                            </div>
                          </div>
                           <!--END CASH ASSETS-->

                           <!--COMMODITIES ASSETS-->
                          <div class="col-12 mb-2" v-if="item.commodities && item.commodities.length > 0">
                            <div class="bold green-text border-bottom py-2">Commodities</div>
                            <div class="row m-0 border-bottom align-items-center" v-for="(asset, key) in item.commodities" :key="key">
                              <div class="col p-1">
                                <div class="row">
                                  <div class="col-12 bold font15" v-if="asset.description">
                                    {{asset.description}}
                                  </div>
                                  <div class="col-12 font12" v-if="asset.commodityType">
                                    {{displayDescription(asset.commodityType)}}<span class="ms-1" v-if="asset.commodityType === 'GOLD_JEWELLERY' || asset.commodityType === 'GOLD_KRUGERRAND_COIN'">{{ displayDescription(asset.proportion) }}</span>
                                  </div>
                                  <div class="col-12 font12">
                                    {{ (asset.commodityType === 'GOLD_KRUGERRAND_COIN' ? `${asset.quantity} units` : `${asset.weightInGrams}g` ) }}
                                  </div>
                                </div>
                              </div>
                              <div class="col-auto text-end bold font15">
                                {{displayAmount(asset.amount)}}
                              </div>
                            </div>
                          </div>
                          <!--END COMMODITIES ASSETS-->

                          <!--DEBTORS ASSETS-->
                          <div class="col-12 mb-2" v-if="item.debtorAssets && item.debtorAssets.length > 0">
                            <div class="bold green-text border-bottom py-2">Debtors Assets</div>
                            <div class="row m-0 border-bottom align-items-center" v-for="(asset, key) in item.debtorAssets" :key="key">
                              <div class="col p-1">
                                <div class="row">
                                  <div class="col-12 bold font15" v-if="asset.description">
                                    {{asset.description}}
                                  </div>
                                  <div class="col-12 font12" v-if="asset.debtorsAssetType">
                                    {{ displayDescription(asset.debtorsAssetType) }}
                                  </div>
                                </div>
                              </div>
                              <div class="col-auto text-end bold font15">
                                {{displayAmount(asset.amount)}}
                              </div>
                            </div>
                          </div>
                          <!--END DEBTORS ASSETS-->

                          <!--INVESTMENT ASSETS-->
                          <div class="col-12 mb-2" v-if="item.investmentAssets && item.investmentAssets.length > 0">
                            <div class="bold green-text border-bottom py-2">Investment Assets</div>
                            <div class="row m-0 border-bottom align-items-center" v-for="(asset, key) in item.investmentAssets" :key="key">
                              <div class="col p-1">
                                <div class="row">
                                  <div class="col-12 bold font15" v-if="asset.description">
                                    {{asset.description}}
                                  </div>
                                  <div class="col-12 font12" v-if="asset.investmentAssetType">
                                    {{ displayDescription(asset.investmentAssetType) }}
                                  </div>
                                  <div class="col-12 font12">
                                    {{asset.currency.symbol}} {{parseFloat(Math.round((asset.amount) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
                                  </div>
                                </div>
                              </div>
                              <div class="col-auto text-end bold font15">
                                {{displayAmount(asset.amount)}}
                              </div>
                            </div>
                          </div>
                          <!--END INVESTMENT ASSETS-->

                          <!--PARTNERSHIP ASSETS-->
                          <div class="col-12 mb-2" v-if="item.partnershipAssets && item.partnershipAssets.length > 0">
                            <div class="bold green-text border-bottom py-2">Partnership Assets</div>
                            <div class="row m-0 border-bottom align-items-center" v-for="(asset, key) in item.partnershipAssets" :key="key">
                              <div class="col p-1">
                                <div class="row">
                                  <div class="col-12 bold font15" v-if="asset.companyName">
                                    {{asset.companyName}}
                                  </div>
                                  <div class="col-12 font12" >
                                    {{displayAmount(asset.netWorth)}} | {{asset.percentageOwned}}%
                                  </div>
                                </div>
                              </div>
                              <div class="col-auto text-end bold font15">
                                {{displayAmount(asset.total)}}
                              </div>
                            </div>
                          </div>
                          <!--END PARTNERSHIP ASSETS-->
                          <!--POLICY ASSETS-->
                          <div class="col-12 mb-2" v-if="item.policyAssets && item.policyAssets.length > 0">
                            <div class="bold green-text border-bottom py-2">Policy Assets</div>
                            <div class="row m-0 border-bottom align-items-center" v-for="(asset, key) in item.policyAssets" :key="key">
                              <div class="col p-1">
                                <div class="row">
                                  <div class="col-12 bold font15" v-if="asset.description">
                                    {{asset.description}}
                                  </div>
                                  <div class="col-12 font12" v-if="asset.policiesAssetType">
                                    {{ displayDescription(asset.policiesAssetType) }}
                                  </div>
                                </div>
                              </div>
                              <div class="col-auto text-end bold font15">
                                {{displayAmount(asset.amount)}}
                              </div>
                            </div>
                          </div>
                          <!--END POLICY ASSETS-->
                        </div>
                      </div>
                      <!--END ASSETS-->
                      <!--LIABILITIES-->
                      <div class="col-12 col-md-9 p-0 mb-3">
                        <Button color="green" :btnText="'Liabilities | ' + displayAmount(item.netLiabilities)" alignment="justify-content-between" icon="arrow" @buttonClicked="showLiabilities = !showLiabilities" width="100">
                          <IconArrowDown size="size16" v-if="showLiabilities"/>
                          <IconArrowForward size="size16" v-else />
                        </Button>
                        <div class="row mx-0 m-3" v-if="showLiabilities">
                          <!--BASIC LIABILITIES-->
                          <div class="col-12 mb-2" v-if="item.basicLiabilities && item.basicLiabilities.length > 0">
                            <div class="bold green-text border-bottom py-2">Basic Liabilities</div>
                            <div class="row m-0 border-bottom align-items-center" v-for="(liability, key) in item.basicLiabilities" :key="key">
                              <div class="col p-1">
                                <div class="row">
                                  <div class="col-12 bold font15" v-if="liability.description">
                                    {{liability.description}}
                                  </div>
                                  <div class="col-12 font12" v-if="liability.zakaatBasicLiabilityType">
                                    {{ displayDescription(liability.zakaatBasicLiabilityType) }}
                                  </div>
                                </div>
                              </div>
                              <div class="col-auto text-end bold font15">
                                {{displayAmount(liability.amount)}}
                              </div>
                            </div>
                          </div>
                          <!--END BASIC LIABILITIES-->
                          <!--Home Loans LIABILITIES-->
                          <div class="col-12 mb-2" v-if="item.zakaatHomeLoanLiabilities && item.zakaatHomeLoanLiabilities.length > 0">
                            <div class="bold green-text border-bottom py-2">Home Loan Liabilities</div>
                            <div class="row m-0 border-bottom align-items-center" v-for="(liability, key) in item.zakaatHomeLoanLiabilities" :key="key">
                              <div class="col p-1">
                                <div class="row">
                                  <div class="col-12 bold font15" v-if="liability.description">
                                    {{liability.description}}
                                  </div>
                                  <div class="col-12 font12" v-if="liability.creditProvider">
                                    {{ liability.creditProvider }}
                                  </div>
                                </div>
                              </div>
                              <div class="col-auto text-end bold font15">
                                {{displayAmount(liability.monthlyInstalments)}}
                              </div>
                            </div>
                          </div>
                          <!--END Home Loans LIABILITIES-->
                          <!--VEHICLE LIABILITIES-->
                          <div class="col-12 mb-2" v-if="item.zakaatVehicleLiabilities && item.zakaatVehicleLiabilities.length > 0">
                            <div class="bold green-text border-bottom py-2">Vehicle Liabilities</div>
                            <div class="row m-0 border-bottom align-items-center" v-for="(liability, key) in item.zakaatVehicleLiabilities" :key="key">
                              <div class="col p-1">
                                <div class="row">
                                  <div class="col-12 bold font15" v-if="liability.description">
                                    {{liability.description}}
                                  </div>
                                  <div class="col-12 font12" v-if="liability.creditProvider">
                                    {{ liability.creditProvider }}
                                  </div>
                                </div>
                              </div>
                              <div class="col-auto text-end bold font15">
                                {{displayAmount(liability.monthlyInstalments)}}
                              </div>
                            </div>
                          </div>
                          <!--END VEHICLE LIABILITIES-->
                        </div>
                      </div>
                      <!--END LIABILITIES-->
                    </div>
                  </div>
                </div>
                <!--DETAILS-->
                <div class="row m-0 justify-content-center">
                  <!--DESKTOP-->
                  <div class="col-12 col-md-9 px-0 my-3">
                    <div class="row align-items-center justify-content-center mb-3">
                      <div class="col-9 col-md-6 text-center green-text mb-2">
                        <div class="row">
                          <div class="col-12">
                            <Button color="grey" width="100" :btnText="item.islamicDate" />
                          </div>
                          <div class="col-12 medium green-text font10 letter_spacing">
                            ISLAMIC DATE
                          </div>
                        </div>
                      </div>
                      <div class="col-9 col-md-6 text-center green-text mb-2">
                        <div class="row">
                          <div class="col-12">
                            <Button color="grey" width="100" :btnText="date" />
                          </div>
                          <div class="col-12 medium green-text font10 letter_spacing">
                            GREGORIAN DATE
                          </div>
                        </div>
                      </div>
                      <div class="col-6 text-center green-text mb-2">
                        <div class="row">
                          <div class="col-12">
                            <Button color="grey" width="100" :btnText="displayAmount(item.goldRatePerGram)" />
                          </div>
                          <div class="col-12 medium green-text font10 letter_spacing">
                            GOLD RATE (GRAMS)
                          </div>
                        </div>
                      </div>
                      <div class="col-6 text-center green-text mb-2">
                        <div class="row">
                          <div class="col-12">
                            <Button color="grey" width="100" :btnText="displayAmount(item.silverRatePerGram)" />
                          </div>
                          <div class="col-12 medium green-text font10 letter_spacing">
                            SILVER RATE (GRAMS)
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row align-items-center justify-content-center">
                      <div class="col-md-6 text-center green-text mb-2">
                        <div class="row">
                          <div class="col-12">
                            <Button color="green" width="100" :btnText="displayTotal" />
                          </div>
                          <div class="col-12 medium green-text font10 letter_spacing">
                            ZAKAAH AMOUNT
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row align-items-center justify-content-center">
                      <div class="col-11 col-md-10 text-center mb-2 font12">
                        <b>Disclaimer:</b> Financial details inputted in the Zakaat calculator are only viewable to you; the donor.
                      </div>
                    </div>
                  </div>
                  <!--END DESKTOP-->
                  <!--MOBILE-->
                  <!-- <div class="col-12 px-0 my-3 d-md-none">
                    <div class="row align-items-center">
                      <div class="col-6 pe-1 text-center green-text">
                        <Button color="grey" width="100" :btnText="frequency" />
                      </div>
                      <div class="col-6 ps-1 text-center green-text">
                        <Button color="grey" width="100" :btnText="date" />
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-6 pe-1 text-center">
                        <div class="medium green-text font10 letter_spacing">
                          FREQUENCY
                        </div>
                      </div>
                      <div class="col-6 ps-1 text-center">
                        <div class="medium green-text font10 letter_spacing">
                          NEXT PAYMENT
                        </div>
                      </div>
                    </div>
                    <div class="row align-items-center mt-3">
                      <div class="col-12 text-center green-text">
                        <Button color="green" width="100" :btnText="displayTotal" />
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-12 text-center">
                        <div class="medium green-text font10 letter_spacing">
                          DONATION TOTAL
                        </div>
                      </div>
                    </div>
                  </div> -->
                  <!--END MOBILE-->
                </div>
                <!--END DETAILS-->
              </div>
            </div>
          </div>
          <!--BUTTONS-->
          <div class="col-12 px-4 align-self-end background-green">
            <div class="row py-3 align-items-center justify-content-center" >
              <div class="col-11 col-md-5 px-2 mb-2 mb-md-0" >
                <Button color="green_light" size="" width="100" btnText="Download Summary" icon="arrow" @buttonClicked="downloadSummary" :isLoading="isDownloading">
                  <IconDownload size="size16" />
                </Button>
              </div>
              <div class="col-11 col-md-5 px-2 mb-2 mb-md-0" >
                <Button color="red" size="" width="100" btnText="Donate Zakaah" icon="arrow" @buttonClicked="donate">
                  <IconArrowForward />
                </Button>
              </div>
              <div class="col-11 col-md-5 px-2 mb-2 mb-md-0 mt-md-2" >
                <Button color="grey" btnText="EDIT" icon="arrow" width="100" @buttonClicked="edit">
                  <IconEdit size="size16" />
                </Button>
              </div>
              <div class="col-11 col-md-5 px-2 mb-2 mb-md-0 mt-md-2" >
                <Button color="red" size="" width="100" btnText="Delete Zakaah Calculation" icon="arrow" @buttonClicked="deleteOrder">
                  <ios-close-icon class="inline-icon" />
                </Button>
              </div>
            </div>
          </div>
          <!--END BUTTONS-->
        </div>
        <div class="position-absolute w-100 start-0">
          <div class="row my-4 mx-0 justify-content-center w-100">
            <div class="col-6 col-sm-5 col-md-3 col-xl-2 item_nav rounded-pill p-2 text-white bold">
              <div class="row align-items-center py-1 justify-content-between w-100 m-0">
                <div class="col-auto cursor hover" @click="goBack" v-if="theKey > 0">
                  <IconArrowBack />
                </div>
                <div class="col-auto disabled" v-else>
                  <IconArrowBack />
                </div>
                <div class="col p-0 text-center">
                  {{theKey + 1}}/{{totalItems}}
                </div>
                <div class="col-auto cursor hover" @click="goForward" v-if="theKey +  1 < totalItems">
                  <IconArrowForward />
                </div>
                <div class="col-auto disabled" v-else>
                  <IconArrowForward />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <Popup @close="close" v-if="isDeleteItem">
        <div class="row mx-0 justify-content-center">
          <div class="col-12 text-center mb-3 green-text bold font19">
            Delete Zakaah Calculation
          </div>
          <div class="col-12 text-center mb-3">
            Are you sure you would like to delete this Zakaah Calculation?
          </div>
          <div class="col-6 px-1 text-center">
            <Button color="grey" btnText="Keep Calculation" width="100" @buttonClicked="close" />
          </div>
          <div class="col-6 px-1 text-center">
            <Button color="red" btnText="Delete" width="100" @buttonClicked="reallyDelete" :disabled="isDeleting" :icon="isDeleting" :isLoading="isDeleting">
            </Button>
          </div>
        </div>
      </Popup>
    </transition>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { DateTime } from 'luxon'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    Popup: defineAsyncComponent(() => import('@/views/Popup.vue')),
    IconArrowBack: defineAsyncComponent(() => import('@/components/icons/IconArrowBack.vue')),
    IconArrowDown: defineAsyncComponent(() => import('@/components/icons/IconArrowDown.vue')),
    IconArrowForward: defineAsyncComponent(() => import('@/components/icons/IconArrowForward.vue')),
    IconDownload: defineAsyncComponent(() => import('@/components/icons/IconDownload.vue')),
    IconEdit: defineAsyncComponent(() => import('@/components/icons/IconEdit.vue')),
    'ios-close-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-close.vue'))
  },
  name: 'Manage Zakaah View Popup',
  props: ['item', 'theKey', 'totalItems'],
  data () {
    return {
      defaultCurrency: process.env.VUE_APP_CURRENCY_SYMBOL,
      showAssets: false,
      showLiabilities: false,
      donation: {
        amount: '',
        currency: process.env.VUE_APP_CURRENCY_SYMBOL,
        projectId: null,
        donationTypes: []
      },
      isDownloading: false,
      isDelete: false,
      isDeleteItem: false,
      isDeleting: false
    }
  },
  methods: {
    ...mapActions(['downloadZakaatSummary', 'addToCart', 'deleteZakaatDetails']),
    goBack () {
      this.$emit('goBack')
    },
    goForward () {
      this.$emit('goForward')
    },
    displayAmount (val) {
      return this.defaultCurrency + ' ' + parseFloat(Math.round(val * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    displayDescription (val) {
      if (typeof val === 'string' || val instanceof String) {
        const splitItem = val.split('_')
        const arr = []
        splitItem.forEach(item => {
          const lowercase = item.toLowerCase()
          const caps = lowercase.charAt(0).toUpperCase() + lowercase.slice(1)
          arr.push(caps)
        })
        const str = arr.slice(0, arr.length).join(' ')
        return str
      }
    },
    async downloadSummary () {
      this.isDownloading = true
      await this.downloadZakaatSummary(this.item.zakaatDetailID)
      this.isDownloading = false
    },
    edit () {
      this.$router.push('/zakaahcalculator/' + this.theKey)
    },
    donate () {
      this.addDonationToCart()
      this.$router.push('/checkout/0')
    },
    addDonationToCart () {
      this.selectedProject[0].projectDonationTypeMaps.forEach((dType) => {
        const obj = { type: dType, amount: null }
        if (dType.donationType.donationTypeID !== 1) {
          this.donation.donationTypes.push(obj)
        } else {
          this.donation.donationTypes.push({ type: dType, amount: this.item.totalZakaat })
        }
      })
      this.donation.projectId = this.selectedProject[0].projectID
      if (!this.donation.currency) {
        this.donation.currency = this.defaultCurrency
      }
      this.donation.amount = this.item.totalZakaat
      this.addToCart(this.donation)
      this.donation = {
        amount: '',
        currency: this.defaultCurrency,
        projectId: null,
        donationTypes: []
      }
    },
    deleteOrder () {
      this.isDeleteItem = true
    },
    async reallyDelete () {
      this.isDeleting = true
      await this.deleteZakaatDetails(this.item.zakaatDetailID)
      this.isDeleting = false
      this.isDeleteItem = false
      this.$emit('close')
    },
    close () {
      this.isDeleteItem = false
    }
  },
  computed: {
    ...mapGetters([
      'adminProjects'
    ]),
    isUK () {
      if (process.env.VUE_APP_COUNTRY === 'UK') {
        return true
      } else {
        return false
      }
    },
    displayTotal () {
      return this.defaultCurrency + ' ' + parseFloat(Math.round(this.item.totalZakaat * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    date () {
      const date = DateTime.fromMillis(parseInt(this.item.spotPriceDate))
      return date.toLocaleString(DateTime.DATE_HUGE)
    },
    selectedProject () {
      if (this.isUK) {
        return this.adminProjects.filter(project => {
          if (project.projectID !== 9) {
            return false
          } else {
            return true
          }
        })
      } else {
        return this.adminProjects.filter(project => {
          if (project.projectID !== 28) {
            return false
          } else {
            return true
          }
        })
      }
    }
  }
}
</script>
<style scoped>
.full_popup {
  position: fixed;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  left: 0;
  top: 0;
}
.card_item {
  background-color: #F4F4F4F7;
  border-radius: 22px;
  box-shadow: 2px 8px 23px 3px rgb(0 0 0 / 20%);
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.card_item_inner {
  background-color: rgb(249, 250, 251);
  border-radius: 15px;
  /* box-shadow: 2px 8px 23px 3px rgb(0 0 0 / 20%); */
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.holder {
  position: relative;
  z-index: 2;
}
.above {
  z-index: inherit;
}
.bg_holder {
  position: absolute;
  left: -1.5rem;
  top: -1.5rem;
  z-index: 1;
  background-image: url(../../../../../../assets/images/bg.png);
  background-size: 32% auto;
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  max-width: calc(100% + 3rem);
  width: calc(100% + 3rem);
  height: calc(100% + 1.5rem);
  padding: 0;
}
.background {
  background-image: linear-gradient(180deg, #fffffff7 0%, #ffffff 100%);
  position: absolute;
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
  background-position: top center;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.card_title {
  font-family: "quicksand_medium", Sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  color: var(--green-color-dark);
  letter-spacing: 4.3px;
}
.border_bottom {
  border-bottom: 1px solid var(--gold-color);
}
.profile_img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  position: relative;
  border: 2px solid #fff;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 5px 5px 35px 0px rgb(0 0 0 / 17%);
}
.profile_img img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.item_img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
  background-color: rgba(0, 0, 0, 0.1);
}
.item {
  color: #4E5356;
  font-size: 15px;
}
.delete {
  color: #E0E0E0;
  cursor: pointer;
}
.delete:hover {
  color: var(--red-color);
}
.borders {
  border-bottom: 1px solid #E0E0E0;
}
.borders:hover {
  background-color: rgb(244, 244, 244);
}
.hide {
  visibility: hidden;
}
.background-grey {
  background-color: rgb(244, 244, 244);
}
.item_nav {
  background-color: rgba(0, 0, 0, 0.53);
}
.hover:hover {
  color: var(--gold-color);
}
.disabled {
  opacity: 0.7;
}
</style>
